import React from 'react';
import Image from 'gatsby-image';
import { ContentfulLogoItem } from '../../graphql-types';

interface LogosProps {
  logos: ContentfulLogoItem[];
}

export default function Logos({ logos }: LogosProps) {
  return (
    <div
      css={{
        display: 'grid',
        gridGap: 48,
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        alignItems: 'center',
        maxWidth: '1050px',
        margin: '0 auto',
      }}
    >
      {logos &&
        logos.map((logo) => {
          return (
            logo.isDisplayed && (
              <Image
                key={logo.id}
                fixed={logo.image.fixed}
                alt={logo.image.title}
                css={{ maxWidth: 120, margin: '0 auto' }}
              />
            )
          );
        })}
    </div>
  );
}
