import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';
import presets from '../../styles/presets';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';

interface StyledInfoIconProps {
  isLeftSide: boolean;
  backgroundColor: string;
  iconColor: string;
  textColor: string;
}

const StyledInfoIcon = styled.span<StyledInfoIconProps>`
  margin-left: 8px;
  position: absolute;

  &:hover {
    cursor: pointer;
  }

  .popover {
    transition: opacity 0.5s ease-out;

    font-size: 12px;
    font-family: ${['Open Sans'].concat(SYSTEM_FONTS).join(', ')};
    color: ${({ textColor }) => textColor};

    position: absolute;
    top: -84px;

    width: 250px;
    padding: 16px;
    border-radius: 10px;
    background-color: ${({ backgroundColor }) => backgroundColor};

    opacity: 0;
    height: 0;
    overflow: hidden;
    display: none;

    ${({ isLeftSide }) =>
      isLeftSide
        ? `
      left: -190px;
      `
        : `
        right: -190px;
      `}

    ${presets.sm} {
      ${({ isLeftSide }) =>
        isLeftSide
          ? `
      left: -200px;
      `
          : `
          right: -215px;
      `}
    }
  }

  &:hover .popover {
    display: block;
    opacity: 1;
    height: auto;
  }

  tspan {
    fill: ${({ iconColor }) => iconColor};
  }
`;

const InfoIconComp = ({
  msg,
  isLeftSide = false,
  backgroundColor = '#fff',
  iconColor = hexToRgba('#fff', 0.8),
  textColor = '#160b2c',
}: {
  msg: string;
  isLeftSide?: boolean;
  backgroundColor?: string;
  iconColor?: string;
  textColor?: string;
}) => {
  if (!msg) return null;
  return (
    <StyledInfoIcon {...{ isLeftSide, backgroundColor, iconColor, textColor }}>
      <InfoIcon css={{ transform: 'scale(0.88)', position: 'relative' }} />
      <div className="popover">{msg}</div>
    </StyledInfoIcon>
  );
};

export default InfoIconComp;
