import React, { useState, useCallback, ChangeEvent } from 'react';
import Container from '../Homepage/Container';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { css } from '@emotion/core';
import { ContentfulContentBlock } from '../../graphql-types';
import presets from '../../styles/presets';
import bgImage from '../../assets/images/employers-demo-bg.svg';
import { SYSTEM_FONTS } from '../../styles/typography';
import Button from '../common/Button';
import hexToRgba from 'hex-to-rgba';
import { isEmail } from '../../utils/validation';
import { MonoFontLabel } from '../common/typography';

const Demo: React.FC<ContentfulContentBlock> = ({
  prefix,
  title,
  text,
  callToAction,
}) => {
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleSubmit = async () => {
    if (isEmail(email)) {
      const { msg } = await addToMailchimp(email);
      setEmail('');
      alert(msg);
    } else {
      setInvalidEmail(true);
    }
  };

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    isEmail(value) && setInvalidEmail(false);
  }, []);

  return (
    <div
      css={css`
        background-image: url(${bgImage});
        background-position: 50% 50%;

        background-repeat: no-repeat;
        background-size: cover;

        min-height: 555px;

        margin-top: -110px;
        padding-top: 128px;

        ${presets.md} {
          background-position: 33% 50%;
        }
      `}
    >
      <Container
        css={{
          maxWidth: 509 + 24 * 2,
          textAlign: 'center',
        }}
      >
        {prefix && <MonoFontLabel>{prefix}</MonoFontLabel>}
        <h4
          css={{
            fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 28,
            color: '#fff',
            marginBottom: 18,
            [presets.md]: {
              fontSize: 40,
            },
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          css={{
            fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 14,
            color: '#fff',
            marginBottom: 39,

            [presets.lg]: {
              fontSize: 18,
            },
          }}
        >
          {text && text.childMarkdownRemark.rawMarkdownBody}
        </p>

        <div
          css={css`
            *:focus {
              outline: 0;
            }
          `}
        >
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={handleInputChange}
            css={{
              position: 'static',
              opacity: 1,
              maxWidth: 360,
              width: '100%',
              padding: '16px 10px',
              marginBottom: 19,
              textAlign: 'center',
              backgroundColor: 'transparent',
              color: hexToRgba('#FFFDFC', 0.6),
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 14,
              border: invalidEmail
                ? `1px solid red`
                : `1px solid ${hexToRgba('#FFFDFC', 0.6)}`,
              borderRadius: 6,
            }}
          />
        </div>

        {callToAction && callToAction.isDisplayed && callToAction.url && (
          <Button onClick={handleSubmit} css={{ maxWidth: 360, width: '100%' }}>
            {callToAction.text}
          </Button>
        )}
      </Container>
    </div>
  );
};

export default Demo;
