import React, { useState, useMemo } from 'react';
import {
  ContentfulCalculator,
  ContentfulCalculatorNumbers,
} from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import presets from '../../styles/presets';
import InfoIconComp from '../Programs/InfoIcon';
import CalculatorSlider from './CalculatorSlider';

const stylesHeading = {
  fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
  fontSize: 14,
  color: hexToRgba('#160B2C', 0.8),
  marginBottom: 0,
};

const stylesMainNumber = {
  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
  fontSize: 50,
  fontWeight: 500,
  color: '#160B2C',
  marginBottom: 32,
};

const stylesSecondNumber = {
  fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
  fontSize: 46,
  color: '#160B2C',
  marginBottom: 0,
};

const CalculatorComponent = ({
  calculatorData,
  calculatorNumbers,
}: {
  calculatorData: ContentfulCalculator;
  calculatorNumbers: ContentfulCalculatorNumbers;
}) => {
  const {
    title,
    leftHeading,
    leftInfo,
    rightHeading,
    rightInfo,
    currency,
  } = calculatorData;

  const {
    defaultEmployeesNumber,
    maximumEmployeesNumber,
    peopleAffectedPercentage,
    costPerEmployee,
  } = useMemo(() => calculatorNumbers, [calculatorNumbers]);

  const [value, setValue] = useState(defaultEmployeesNumber);
  const affectedEmployeesValue = Math.floor(value * peopleAffectedPercentage);

  return (
    <div>
      {/* Number of employees */}
      <p css={stylesHeading}>{title}</p>
      <p css={stylesMainNumber}>{value}</p>
      {/* Slider */}
      <CalculatorSlider
        {...{ setValue, maximumEmployeesNumber, defaultEmployeesNumber }}
      />
      {/* Box */}
      <div
        css={{
          maxWidth: 820,
          margin: '41px auto',
          padding: '35px',
          backgroundColor: hexToRgba('#FFDAD5', 0.25),
          borderRadius: 20,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',

          [presets.sm]: {
            flexDirection: 'row',
            padding: '52px 70px',
          },
        }}
      >
        <div css={{ marginBottom: 50, [presets.sm]: { marginBottom: 0 } }}>
          <p css={stylesHeading}>
            {leftHeading}
            <InfoIconComp
              msg={leftInfo && leftInfo.childMarkdownRemark.rawMarkdownBody}
              backgroundColor="#9775C1"
              iconColor={hexToRgba('#160B2C', 0.8)}
              textColor="#fff"
              isLeftSide
            />
          </p>
          <span css={{ ...stylesSecondNumber, display: 'block' }}>
            {affectedEmployeesValue}
          </span>
        </div>
        <div>
          <p css={stylesHeading}>
            {rightHeading}
            <InfoIconComp
              css={{ color: '#000' }}
              msg={rightInfo && rightInfo.childMarkdownRemark.rawMarkdownBody}
              backgroundColor="#9775C1"
              iconColor={hexToRgba('#160B2C', 0.8)}
              textColor="#fff"
              isLeftSide
            />
          </p>
          <span css={{ ...stylesSecondNumber, display: 'block' }}>
            <sup css={[stylesSecondNumber, { marginRight: 5, fontSize: 36 }]}>
              {currency}
            </sup>
            {affectedEmployeesValue * costPerEmployee}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalculatorComponent;
