import React from 'react';
import presets from '../../styles/presets';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import hexToRgba from 'hex-to-rgba';
import { ContentfulContentBlock } from '../../graphql-types';
import { Row, Col } from 'emotion-flex';
import FoundersTick from './FoundersTick';
import ImpactCalendar from './ImpactCalendar';
import { FormattedMessage } from 'react-intl';

interface ProblemsProps {
  impactSection: ContentfulContentBlock;
  impactSectionNumber: string;
}

const ImpactSection: React.FC<ProblemsProps> = ({
  impactSection,
  impactSectionNumber,
}) => {
  return (
    <section>
      <div
        css={css`
          margin: 0 auto;
          padding: 157px 0 133px;

          ${presets.md} {
            min-height: 400px;
          }
        `}
      >
        <Container css={css``}>
          <Row css={{ maxWidth: 1400, margin: '0 auto' }}>
            {/* 1 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                margin: '0 auto 58px',
                padding: 0,
                [presets.md]: { padding: '32px 30px 0 ', margin: '0 auto' },
              }}
            >
              <div>
                <p
                  css={{
                    textAlign: 'center',
                    fontFamily: ['Recoleta Alt']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 18,
                    fontWeight: 500,
                    color: '#160b2c',
                    marginBottom: 26,
                  }}
                >
                  -{' '}
                  <FormattedMessage
                    id="employers.march"
                    defaultMessage="March"
                  />
                  2020 -
                </p>
                <ImpactCalendar />
              </div>
            </Col>
            {/* 2 COL */}
            <Col
              xs={12}
              md={6}
              css={{
                [presets.md]: { padding: '0 0 0 100px' },
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  textAlign: 'left',
                }}
              >
                <p
                  css={{
                    fontFamily: ['Source Code Pro']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 12,
                    color: '#489F9D',
                    marginBottom: 8,
                    fontWeight: 700,
                  }}
                >
                  {impactSection.prefix}
                </p>
                <p
                  css={{
                    fontFamily: ['Recoleta Alt']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 24,
                    lineHeight: '30px',
                    fontWeight: 500,
                    color: '#160b2c',
                    marginBottom: 16,
                    maxWidth: 450,

                    [presets.md]: {
                      fontSize: 36,
                      lineHeight: '40px',
                    },
                  }}
                >
                  {impactSection.title}
                  <sup
                    css={{
                      fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                      marginLeft: 5,
                      fontSize: 12,
                    }}
                  >
                    {impactSectionNumber}
                  </sup>
                </p>
                <p
                  css={{
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    fontSize: 16,
                    color: hexToRgba('#160B2C', 0.8),
                    marginBottom: 20,
                    maxWidth: 500,

                    [presets.lg]: {
                      fontSize: 18,
                    },
                  }}
                >
                  {impactSection.text.childMarkdownRemark.rawMarkdownBody}
                </p>
                {impactSection.items.map((item) => {
                  return (
                    <FoundersTick key={item.id} simpleText={item.heading} />
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ImpactSection;
