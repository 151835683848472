import React from 'react';
import presets from '../../styles/presets';
import Container from '../Homepage/Container';
import { css } from '@emotion/core';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ContentfulContentBlock } from '../../graphql-types';
import hexToRgba from 'hex-to-rgba';
import { Link } from 'gatsby';

const References = (props: ContentfulContentBlock) => {
  const { title, itemsLongText } = props;
  return (
    <section
      css={css`
        margin: 0 auto 129px;
        ${presets.sm} {
          margin: 0 auto 112px;
        }
      `}
    >
      <Container
        css={css`
          max-width: 800px;
        `}
      >
        <div
          css={css`
            background-color: ${hexToRgba('#E2E8F7', 0.6)};
            border-radius: 12px;
            padding: 30px 20px 36px;

            ${presets.sm} {
              padding: 46px 60px 64px;
            }
          `}
        >
          <p
            css={{
              fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 26,
              fontWeight: 500,
              color: '#160B2C',
              marginBottom: 38,
            }}
          >
            {title}
          </p>
          {itemsLongText.map((item, index) => {
            return (
              <Link
                to={item.text.childMarkdownRemark.rawMarkdownBody}
                target="_blank"
                key={item.id}
                css={css`
                  &:hover {
                    color: ${hexToRgba('#489F9D', 0.9)};
                    text-decoration: none;
                  }
                `}
              >
                <p
                  css={css`
                    font-family: ${['Open Sans']
                      .concat(SYSTEM_FONTS)
                      .join(', ')};
                    font-size: 16;
                    color: ${hexToRgba('#160B2C', 0.8)};
                    margin-bottom: 38;
                    &:hover {
                      color: ${hexToRgba('#489F9D', 0.9)};
                    }
                  `}
                >
                  {index + 1}. {item.text.childMarkdownRemark.rawMarkdownBody}
                </p>
              </Link>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default References;
