import React, { useState, ReactNode } from 'react';
import { css } from '@emotion/core';
import Container from '../Homepage/Container';
import presets from '../../styles/presets';
import {
  ContentfulHeadingTextImage,
  ContentfulLogoItem,
} from '../../graphql-types';
import { Col, Row } from 'emotion-flex';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import styled from '@emotion/styled';
import Logos from './Logos';
import GatsbyImage from 'gatsby-image';
import ArrowIcon from '../../assets/images/arrow-icon.svg';

interface ArrowImageProps {
  isActive: boolean;
}

interface ItemBoxCompProps {
  isActive: boolean;
  onClick: () => void;
  children: ReactNode;
  showBorderBottom: boolean;
}

export const ItemBoxComp: React.FC<ItemBoxCompProps> = ({
  isActive,
  onClick,
  children,
  showBorderBottom,
}) => {
  const activeStyle = {
    transition: '0.3s ease-in-out',
    padding: '24px 19px 24px 19px',
    minHeight: 180,
    backgroundColor: hexToRgba('#ffdad5', 0.25),
    borderRadius: 10,

    [presets.md]: {
      padding: '50px 25px 45px 32px',
    },
  };

  const inactiveStyle = {
    transition: '0.3s ease-in-out',
    padding: '0 19px 0 19px',
    minHeight: 80,
    backgroundColor: 'none',
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: showBorderBottom
      ? `0.2px solid ${hexToRgba('#160B2C', 0.2)}`
      : 'none',

    [presets.md]: {
      padding: '0 25px 0 32px',
    },
  };

  return (
    <div css={isActive ? activeStyle : inactiveStyle} {...{ onClick }}>
      {children}
    </div>
  );
};

const StyledHeading = styled.p`
  font-family: ${['Recoleta Alt'].concat(SYSTEM_FONTS).join(', ')};
  font-size: 20px;
  font-weight: bold;
  color: ${hexToRgba('#160B2C', 0.8)};
  margin-bottom: 0;
`;

const ArrowImage = styled.img<ArrowImageProps>`
  margin-bottom: 0;
  margin-left: 7px;
  transition: 0.25s ease-in-out;
  max-width: 16px;
  transform: ${({ isActive }) => (isActive ? 'rotate(0)' : 'rotate(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

const LogosAndBenefits = ({
  headline,
  logos,
  boxes,
}: {
  headline: string;
  logos: ContentfulLogoItem[];
  boxes: ContentfulHeadingTextImage[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <section css={{ marginTop: -350 }}>
      <div
        css={css`
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          padding-top: 417px;
          min-height: 1800px;
        `}
      >
        <Container>
          <p
            css={{
              fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
              fontSize: 14,
              color: hexToRgba('#160B2C', 0.8),
              marginTop: 8,
              marginBottom: 46,
              textAlign: 'center',

              [presets.md]: { marginTop: 0 },
            }}
          >
            {headline}
          </p>
          <Logos logos={logos} />
          <Row css={{ maxWidth: 1050 + 44, margin: '219px auto 0' }}>
            <Col
              xs={12}
              md={6}
              css={{
                display: 'none',
                height: 600,
                padding: '0 11px',
                [presets.md]: {
                  display: 'block',
                },
              }}
            >
              <GatsbyImage
                fluid={boxes[activeIndex].image.fluid}
                css={{
                  width: '100%',
                  height: 450,
                  borderRadius: 10,
                  [presets.lg]: { height: 600 },
                }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              css={{
                margin: '0 auto',
              }}
            >
              {boxes.map((el, index) => {
                return (
                  <ItemBoxComp
                    key={el.heading}
                    isActive={activeIndex === index}
                    showBorderBottom={activeIndex - index !== 1}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                  >
                    {activeIndex === index ? (
                      <>
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                            minHeight: 40,
                          }}
                        >
                          <StyledHeading>{el.heading}</StyledHeading>
                          <div css={{ display: 'flex', userSelect: 'none' }}>
                            <ArrowImage
                              src={ArrowIcon}
                              alt="Arrow"
                              isActive={activeIndex === index}
                            />
                          </div>
                        </div>
                        <div css={{ maxWidth: 400 }}>
                          <p
                            css={{
                              fontFamily: ['Open Sans']
                                .concat(SYSTEM_FONTS)
                                .join(', '),
                              fontSize: 14,
                              color: hexToRgba('#160B2C', 0.8),
                              marginTop: 8,
                              marginBottom: 0,

                              [presets.md]: { marginTop: 0 },
                            }}
                          >
                            {el.text.childMarkdownRemark.rawMarkdownBody}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <StyledHeading>{el.heading}</StyledHeading>
                        <div css={{ display: 'flex', userSelect: 'none' }}>
                          <ArrowImage
                            src={ArrowIcon}
                            alt="Arrow"
                            isActive={activeIndex === index}
                          />
                        </div>
                      </>
                    )}
                  </ItemBoxComp>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default LogosAndBenefits;
