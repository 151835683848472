import React, { useRef, useCallback } from 'react';
import { graphql } from 'gatsby';
import presets from '../styles/presets';
import { SEO } from '../components';
import { ContentfulEmployersPage } from '../graphql-types';
import { withLayout } from '../components/Layout';
import {
  Hero,
  LogosAndHighlights,
  Problems,
  ProblemAndImpact,
  ImpactSection,
  CalculatorSection,
  SliderWithHeading,
  IntegrationSection,
  Faq,
  References,
} from '../components/Employers';
import Demo from '../components/Employers/Demo';
import bgImage from '../assets/images/employers-top-bg.svg';
import { css } from '@emotion/core';
import { scrollToRef } from '../utils/scrollToRef';

interface OwnProps {
  pathContext: {
    locale: 'en' | 'de';
  };
  data: {
    employers: ContentfulEmployersPage;
  };
}

type Props = OwnProps;

const Employers: React.FC<Props> = ({
  pathContext: { locale },
  data: { employers },
}) => {
  const integrationSectionRef = useRef(null);
  const demoSectionRef = useRef(null);
  const scrollToIntegrationSection = useCallback(() => {
    scrollToRef(integrationSectionRef, 300);
  }, []);
  const scrollToDemoSection = useCallback(() => {
    scrollToRef(demoSectionRef);
  }, []);

  return (
    <>
      <SEO lang={locale} title={employers.seoTitle} />
      <main
        css={{ backgroundColor: '#fffdfc', marginTop: -30, paddingTop: 20 }}
      >
        <Hero
          heading={employers.heroHeadline}
          subhead={employers.heroSubhead}
          heroCTA1={employers.heroCta1}
          heroCTA2={employers.heroCta2}
          {...{ scrollToIntegrationSection, scrollToDemoSection }}
        />
        <div
          css={css`
            background-image: url(${bgImage});
            background-position: 19% 50%;
            background-repeat: no-repeat;
            background-size: cover;

            position: relative;
            z-index: 2;

            margin-top: -120px;
            padding-top: 130px;

            min-height: 3000px;

            ${presets.lg} {
              margin-top: -90px;
              padding-top: 90px;
              background-position: 50% 50%;
            }
          `}
        >
          <LogosAndHighlights
            logos={employers.companies}
            headline={employers.companiesHeadline}
            highlights={employers.highlights}
            highlightsItems={employers.highlightsItems}
            highlightsBubble={employers.highlightsBubble}
          />
          <Problems
            problemsArray={[employers.problemFirst, employers.problemSecond]}
            problemsNumbersArray={[
              employers.problemFirstNumber,
              employers.problemSecondNumber,
            ]}
          />
          <ProblemAndImpact
            problemData={employers.problemSection}
            problemThirdNumber={employers.problemThirdNumber}
          />
          <ImpactSection
            impactSection={employers.impactSection}
            impactSectionNumber={employers.impactSectionNumber}
          />
        </div>
        {employers.isCalculatorSectionDisplayed && (
          <CalculatorSection
            calculatorSection={employers.calculatorSection}
            calculatorData={employers.calculatorValues}
            calculatorNumbers={employers.calculatorNumbers}
            calculatorCta1={employers.calculatorCta1}
            calculatorCta2={employers.calculatorCta2}
            {...{ scrollToIntegrationSection, scrollToDemoSection }}
          />
        )}
        {employers.isClientsSectionDisplayed && (
          <SliderWithHeading sliderData={employers.clientsSection} />
        )}
        <section ref={integrationSectionRef}>
          <IntegrationSection {...employers.integrationSection} />
        </section>
        <section ref={demoSectionRef}>
          <Demo {...employers.demoSection} />
        </section>
        <Faq {...employers.faqSection} />
        <References {...employers.referencesSection} />
      </main>
    </>
  );
};

export const employersPageQuery = graphql`
  query employersPageQuery($locale: String!) {
    employers: contentfulEmployersPage(node_locale: { eq: $locale }) {
      seoTitle
      heroHeadline
      heroSubhead
      heroCta1 {
        text
        url
        isDisplayed
      }
      heroCta2 {
        text
        url
        isDisplayed
      }
      companiesHeadline
      companies {
        id
        image {
          title
          fixed(width: 120) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        isDisplayed
      }
      highlights {
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        heading
        shortText
      }
      highlightsBubble {
        prefix
        heading
        shortText
        image {
          fluid(maxWidth: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      highlightsItems {
        simpleText
      }
      problemFirst {
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      problemFirstNumber
      problemSecond {
        heading
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      problemSecondNumber
      problemSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          prefix
          heading
          shortText
        }
      }
      problemThirdNumber
      impactSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          heading
        }
      }
      impactSectionNumber
      isCalculatorSectionDisplayed
      calculatorSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      calculatorValues {
        title
        leftHeading
        leftInfo {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        rightHeading
        rightInfo {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        currency
      }
      calculatorNumbers {
        defaultEmployeesNumber
        maximumEmployeesNumber
        peopleAffectedPercentage
        costPerEmployee
      }
      calculatorCta1 {
        text
        url
        isDisplayed
      }
      calculatorCta2 {
        text
        url
        isDisplayed
      }
      clientsSection {
        prefix
        title
        itemsLongText {
          prefix
          text {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            fluid: fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      isClientsSectionDisplayed
      integrationSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        items {
          heading
          shortText
        }
      }
      demoSection {
        prefix
        title
        text {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        callToAction {
          text
          url
          isDisplayed
        }
      }
      faqSection {
        prefix
        title
        callToAction {
          text
          url
          isDisplayed
        }
        itemsLongText {
          heading
          text {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      referencesSection {
        title
        itemsLongText {
          text {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
      }
    }
  }
`;

export default withLayout(Employers, { isNewLayout: true, isDarkHeader: true });
