import React from 'react';
import presets from '../../styles/presets';
import hexToRgba from 'hex-to-rgba';

export default function ImpactCalendar() {
  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 350,
        justifyContent: 'center',
        margin: '0 auto',
        [presets.sm]: {
          maxWidth: 500,
        },
      }}
    >
      {[...Array(7).keys()].map((i) => {
        return (
          <div
            key={`part1-${i}`}
            css={{
              width: 34,
              height: 26,
              backgroundColor:
                i > 1 ? hexToRgba('#FFAFA4', 0.6) : hexToRgba('#FFAFA4', 0.15),
              margin: '10px 5px',
              borderRadius: 10,

              [presets.sm]: {
                width: 54,
                height: 40,
                margin: '15px 8px',
              },
            }}
          />
        );
      })}
      {[...Array(10).keys()].map((i) => {
        return (
          <div
            key={`part2-${i}`}
            css={{
              width: 34,
              height: 26,
              backgroundColor: '#9775C1',
              margin: '10px 5px',
              borderRadius: 10,

              [presets.sm]: {
                width: 54,
                height: 40,
                margin: '15px 8px',
              },
            }}
          />
        );
      })}
      <div
        css={{
          width: 34,
          height: 26,
          backgroundColor: hexToRgba('#FFAFA4', 0.6),
          margin: '10px 5px',
          borderRadius: 10,
          position: 'relative',

          [presets.sm]: {
            width: 54,
            height: 40,
            margin: '15px 8px',
          },
        }}
      >
        <div
          css={{
            width: 28.5,
            height: 26,
            backgroundColor: '#9775C1',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,

            position: 'absolute',
            top: 0,
            left: 0,

            [presets.sm]: {
              width: 44,
              height: 40,
            },
          }}
        ></div>
      </div>
      {[...Array(17).keys()].map((i) => {
        return (
          <div
            key={`part3-${i}`}
            css={{
              width: 34,
              height: 26,
              backgroundColor:
                i < 15 ? hexToRgba('#FFAFA4', 0.6) : hexToRgba('#FFAFA4', 0.15),
              margin: '10px 5px',
              borderRadius: 10,

              [presets.sm]: {
                width: 54,
                height: 40,
                margin: '15px 8px',
              },
            }}
          />
        );
      })}
    </div>
  );
}
