import React from 'react';
import HeadingSection from '../HeadingSection/HeadingSection';
import { Container, Row, Col } from 'emotion-flex';
import { ContentfulContentBlock } from '../../graphql-types';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';

import image1 from '../../assets/images/employers-workflow-01.svg';
import image2 from '../../assets/images/employers-workflow-02.svg';
import image3 from '../../assets/images/employers-workflow-03.svg';
import image4 from '../../assets/images/employers-workflow-04.svg';
import image5 from '../../assets/images/employers-workflow-05.svg';
import image6 from '../../assets/images/employers-workflow-06.svg';

const images = [image1, image2, image3, image4, image5, image6];

const IntegrationSection: React.FC<ContentfulContentBlock> = ({
  items,
  ...rest
}) => {
  return (
    <>
      <div
        css={{
          marginTop: -300,
          paddingBottom: 190,
          paddingTop: 300 + 115,
          backgroundColor: '#f6f6f6',

          [presets.sm]: {
            paddingTop: 300 + 136,
            paddingBottom: 255,
          },
        }}
      >
        <HeadingSection {...rest} />
        <Container
          css={{
            margin: '42px auto 0',
            [presets.sm]: { margin: '89px auto 0' },
          }}
        >
          <Row css={{ justifyContent: 'center' }}>
            {items.map((item, index) => {
              return (
                <Col
                  key={item.heading}
                  xs={12}
                  lg={3}
                  css={{
                    backgroundColor: '#fff',
                    borderRadius: 10,
                    height: 120,
                    maxWidth: 320,
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: '32px 13px 14px',

                    [presets.sm]: {
                      margin: 17.5,
                    },
                  }}
                >
                  <img
                    src={images[index]}
                    alt={item.heading}
                    css={{
                      width: 48,
                      height: 48,
                    }}
                  />
                  <div css={{ marginLeft: 12 }}>
                    <h5
                      css={{
                        fontFamily: ['Recoleta Alt']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#160B2C',
                        marginBottom: 7,
                      }}
                    >
                      {item.heading}
                    </h5>
                    <p
                      css={{
                        fontFamily: ['Open Sans']
                          .concat(SYSTEM_FONTS)
                          .join(', '),
                        fontSize: 12,
                        color: hexToRgba('#160B2C', 0.8),
                        marginBottom: 0,
                      }}
                    >
                      {item.shortText}
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default IntegrationSection;
