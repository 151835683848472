import isClient from './isClient';

export const scrollToRef = (
  ref: React.MutableRefObject<HTMLElement>,
  offsetDistance = 0
) => {
  isClient &&
    window.scrollTo({
      behavior: 'smooth',
      top: ref.current.offsetTop + offsetDistance,
    });
};
